import Vue from "vue";
import excel from "vue-excel-export";
import breadcrumbComp from "../../common/breadcrumb-comp";
import draggable from "vuedraggable";
import PartService from "./parts-service";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import commonService from "../../common/js/common-api-service";
import Utility from "../../../shared/utility";
Vue.use(excel);
export default {
  data: () => ({
    userId: EncryptUtility.localStorageDecrypt("userID"),
    actionList: [],
    mainPage: "",
    favouriteList: [],
    pageUrl: "",
    subPage: "",
    showAdd: false,
    subPageUrl: "",
    backSlash: true,
    backSlashParent: true,
    showRefreshDialog: true,
    isEditing: true,
    isFormValid: false,
    tab: null,
    projectList: [],
    partTypeList: [],
    enableValidate: true,
    btnValShow: true,
    partNumberChange: false,
    partDescChange: true,
    projectChange: true,
    partTypeChange: true,
    partNumberValidated: false,
    partNumber: "",
    partDesc: "",
    isPartEditAddFormValid: false,
    rlpPartNum: "",
    cosmetic: "",
    ecoInfo: "",
    search: "",
    searchDisabled: true,
    selectedProject: "",
    selectedPartType: "",
    selectedWarehouse: "",
    showSelectedWare: true,
    searchString: "",
    partNumberSearch: "",
    partNumWarehouse: "",
    numberPart: "",
    descriptionPart: "",
    part_key: "",
    sequence: 0,
    subPartDelDialog: false,
    warehouseDelDialog: false,
    warehouseDialog: false,
    warehouseDataAvlbl: false,
    saveWarehouseData: false,
    showExpansionTables: false,
    wareSelected: false,
    editWarehouseData: false,
    substitutionPartListDialog: false,
    partNumSearched: true,
    showPartDetails: false,
    valPartNumFill: false,
    saveSubstitutionDet: false,
    editSubstitutionDet: false,
    substitutionSaveDisabled: true,
    continueBtnDisable: true,
    enableSalesOrderValue: false,
    enableSalesOrderShowInUI: true,
    enableSalesOrderEnabled: true,
    soStraightSaleValue: false,
    soStraightSaleShowInUI: true,
    soStraightSaleEnabled: true,
    soAdvancedExchangeValue: false,
    soAdvancedExchangeShowInUI: true,
    soAdvancedExchangeEnabled: true,
    soExchangeValue: false,
    soExchangeShowInUI: true,
    soExchangeEnabled: true,
    soCustRepairValue: false,
    soCustRepairShowInUI: true,
    soCustRepairEnabled: true,
    soStraightReturnValue: false,
    soStraightReturnShowInUI: true,
    soStraightReturnEnabled: true,
    enablePurchaseOrdersValue: false,
    enablePurchaseOrdersShowInUI: true,
    enablePurchaseOrdersEnabled: true,
    poStraightPurchaseValue: false,
    poStraightPurchaseShowInUI: true,
    poStraightPurchaseEnabled: true,
    poAdvanceExchangeValue: false,
    poAdvanceExchangeShowInUI: true,
    poAdvanceExchangeEnabled: true,
    poExchangeValue: false,
    poExchangeShowInUI: true,
    poExchangeEnabled: true,
    poStockRepairValue: false,
    poStockRepairShowInUI: true,
    poStockRepairEnabled: true,
    poPOReturnValue: false,
    poPOReturnShowInUI: true,
    poPOReturnEnabled: true,
    bcnValue: false,
    bcnShowInUI: true,
    bcnEnabled: true,
    partDetails: false,
    autoGenerateBCNValue: false,
    autoGenerateBCNShowInUI: true,
    autoGenerateBCNEnabled: true,
    autoGeneratedPrefixValue: "",
    autoGeneratedPrefixShowInUI: true,
    autoGeneratedPrefixEnabled: true,
    serialNoCapturedValue: false,
    serialNoCapturedShowInUI: true,
    serialNoCapturedEnabled: true,
    gobiValue: false,
    gobiShowInUI: true,
    gobiEnabled: true,
    esnIMEIValue: false,
    esnIMEIShowInUI: true,
    esnIMEIEnabled: true,
    ICCIDCapturedValue: false,
    ICCIDCapturedShowInUI: true,
    ICCIDCapturedEnabled: true,
    boxLabelValue: false,
    boxLabelShowInUI: true,
    boxLabelEnabled: true,
    printCommentSheetValue: false,
    printCommentSheetShowInUI: true,
    printCommentSheetEnabled: true,
    promptForNewProductValue: false,
    promptForNewProductShowInUI: true,
    promptForNewProductEnabled: true,
    activateStrikeRuleValue: false,
    activateStrikeRuleShowInUI: true,
    activateStrikeRuleEnabled: true,
    returnWaybillLabelRequiredValue: false,
    returnWaybillLabelRequiredShowInUI: true,
    returnWaybillLabelRequiredEnabled: true,
    promptRevLevelValue: false,
    promptRevLevelShowInUI: true,
    promptRevLevelEnabled: true,
    promptFirmLevelValue: false,
    promptFirmLevelShowInUI: true,
    promptFirmLevelEnabled: true,
    thirdStrikeValue: false,
    thirdStrikeShowInUI: true,
    thirdStrikeEnabled: true,
    minRevLevelValue: "",
    minRevLevelShowInUI: true,
    minRevLevelEnabled: true,
    CRAValue: false,
    CRAShowInUI: true,
    CRAEnabled: true,
    DNCRCVValue: false,
    DNCRCVShowInUI: true,
    DNCRCVEnabled: true,
    IFIRValue: false,
    IFIRShowInUI: true,
    IFIREnabled: true,
    IFIRDaysValue: 0,
    IFIRDaysShowInUI: true,
    IFIRDaysEnabled: false,
    returnsToScrapValue: false,
    returnsToScrapShowInUI: true,
    returnsToScrapEnabled: true,
    DNCBypassScrapValue: false,
    DNCBypassScrapShowInUI: true,
    DNCBypassScrapEnabled: true,
    initialTestTriageValue: false,
    initialTestTriageShowInUI: true,
    initialTestTriageEnabled: true,
    iwTestPathValue: false,
    iwTestPathShowInUI: true,
    iwTestPathEnabled: false,
    owTestPathValue: false,
    owTestPathShowInUI: true,
    owTestPathEnabled: false,
    finalTestPreValue: false,
    finalTestPreShowInUI: true,
    finalTestPreEnabled: true,
    owRepairValue: false,
    owRepairShowInUI: true,
    owRepairEnabled: true,
    iwRepairValue: false,
    iwRepairShowInUI: true,
    iwRepairEnabled: true,
    waitingForPartsValue: false,
    waitingForPartsShowInUI: true,
    waitingForPartsEnabled: true,
    firmwareValue: false,
    firmwareShowInUI: true,
    firmwareEnabled: true,
    finalTestPostValue: false,
    finalTestPostShowInUI: true,
    finalTestPostEnabled: true,
    engineeringEvalValue: false,
    engineeringEvalShowInUI: true,
    engineeringEvalEnabled: true,
    wipeSanitizeValue: false,
    wipeSanitizeShowInUI: true,
    wipeSanitizeEnabled: true,
    cosmeticInspectionValue: false,
    cosmeticInspectionShowInUI: true,
    cosmeticInspectionEnabled: true,
    auditValue: false,
    auditShowInUI: true,
    auditEnabled: true,
    auditSamplingRateValue: "",
    auditSamplingRateShowInUI: true,
    auditSamplingRateEnabled: false,
    harvestValue: false,
    harvestShowInUI: true,
    harvestEnabled: true,
    deleteWarehouse: false,
    subPartDelete: false,
    wareDuplicate: 0,
    subDuplicate: 0,
    panel: [0, 1, 2, 3],
    buildOptionsList: [],
    oemModelList: [],
    modelList: [],
    oemList: [],
    odmList: [],
    unitOfMeasureList: [],
    boxLabelSizeList: [],
    partClassList: [],
    binTypeList: [],
    warehouseList: [],
    iwTestPathList: [],
    owTestPathList: [],
    warehousePartNumList: [],
    listSubstitutionDetails: [],
    listWarehouse: [],
    substitutionList: [],
    kitDuplicate: 0,
    kitBOMDialog: false,
    kitBundleDelete: false,
    kitBundleDelDialog: false,
    kitSearch: "",
    kitPartSearch: false,
    kitBundleDetails: false,
    kitBundleList: [],
    showKitBundleDetails: false,
    listMemberPartDetails: [],
    kitBundleQty: 1,
    kitBundleActive: false,
    kitBundlePrimaryPart: false,
    kitBundleAddOn: false,
    saveKitBundle: false,
    editKitBundle: false,
    memberPartKey: 0,
    kitSearched: true,
    kitBundlePartDis: true,
    projectBrinks: false,
    selectedCountry: "",
    countryList: [],
    formData: {
      part_class: [
        {
          show_in_ui: true,
          enabled: true,
          value: "",
        },
      ],
      oem: [
        {
          show_in_ui: true,
          enabled: true,
          value: "",
        },
      ],
      odm: [
        {
          show_in_ui: true,
          enabled: true,
          value: "",
        },
      ],
      oem_part: [
        {
          show_in_ui: true,
          enabled: true,
          value: "",
        },
      ],
      model: [
        {
          show_in_ui: true,
          enabled: true,
          value: "",
        },
      ],
      oem_model: [
        {
          show_in_ui: true,
          enabled: true,
          value: "",
        },
      ],
      unit_of_measure: [
        {
          show_in_ui: true,
          enabled: true,
          value: "",
        },
      ],
      bin_type: [
        {
          show_in_ui: true,
          enabled: true,
          value: "",
        },
      ],
      bin_limit: [
        {
          show_in_ui: true,
          enabled: true,
          value: 0,
        },
      ],
      packout_ref: [
        {
          show_in_ui: true,
          enabled: true,
          value: "",
        },
      ],
      box_label_size: [
        {
          show_in_ui: true,
          enabled: true,
          value: "",
        },
      ],
      height_width_length: [
        {
          show_in_ui: true,
          enabled: false,
          value: 0,
        },
      ],
      height: [
        {
          value: 0,
        },
      ],
      width: [
        {
          value: 0,
        },
      ],
      length: [
        {
          value: 0,
        },
      ],
      weight: [
        {
          show_in_ui: true,
          enabled: true,
          value: 0,
        },
      ],
      daily_usage: [
        {
          show_in_ui: true,
          enabled: false,
          value: 0,
        },
      ],
      demand: [
        {
          show_in_ui: true,
          enabled: false,
          value: 0,
        },
      ],
      unit_cost: [
        {
          show_in_ui: true,
          enabled: true,
          value: 0.0,
        },
      ],
      sale_price: [
        {
          show_in_ui: true,
          enabled: true,
          value: 0,
        },
      ],
      exchange_price: [
        {
          show_in_ui: true,
          enabled: false,
          value: 0,
        },
      ],
      repair_price: [
        {
          show_in_ui: true,
          enabled: false,
          value: 0,
        },
      ],
      so_warranty_days: [
        {
          show_in_ui: true,
          enabled: true,
          value: 0,
        },
      ],
      fgi_dsi_cutoff: [
        {
          show_in_ui: true,
          enabled: true,
          value: 0,
        },
      ],
      oem_dsi_cutoff: [
        {
          show_in_ui: true,
          enabled: true,
          value: 0,
        },
      ],
      oem_parts_per_skid: [
        {
          show_in_ui: true,
          enabled: true,
          value: 0,
        },
      ],
      cf_buffer_qty: [
        {
          show_in_ui: true,
          enabled: true,
          value: 0,
        },
      ],
      sales_orders: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      so_straight_sale: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      so_advanced_exchange: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      so_exchange: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      so_customer_repair: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      so_straight_return: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      purchase_orders: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      po_straight_purchase: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      po_advanced_exchange: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      po_exchange: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      po_stock_repair: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      po_return: [
        {
          show_in_ui: true,
          enabled: true,
          value: true,
        },
      ],
      bcn: [
        {
          show_in_ui: true,
          enabled: true,
          value: false,
        },
      ],
      auto_generate_bcn: [
        {
          show_in_ui: true,
          enabled: false,
          value: false,
        },
      ],
      auto_generated_prefix: [
        {
          show_in_ui: true,
          enabled: false,
          value: "",
        },
      ],
      serial_number: [
        {
          show_in_ui: true,
          enabled: true,
          value: false,
        },
      ],
      gobi: [
        {
          show_in_ui: true,
          enabled: false,
          value: false,
        },
      ],
      esn_imei: [
        {
          show_in_ui: true,
          enabled: false,
          value: false,
        },
      ],
      iccid: [
        {
          show_in_ui: true,
          enabled: false,
          value: false,
        },
      ],
      box_label: [
        {
          show_in_ui: true,
          enabled: true,
          value: false,
        },
      ],
      print_comment_sheet: [
        {
          show_in_ui: true,
          enabled: true,
          value: false,
        },
      ],
      prompt_for_new: [
        {
          show_in_ui: true,
          enabled: true,
          value: false,
        },
      ],
      third_strike: [
        {
          show_in_ui: true,
          enabled: false,
          value: false,
        },
      ],
      third_strike_visit: [
        {
          show_in_ui: true,
          enabled: false,
          value: 0,
        },
      ],
      return_waybill_required: [
        {
          show_in_ui: true,
          enabled: false,
          value: false,
        },
      ],
      revision_level: [
        {
          show_in_ui: true,
          enabled: false,
          value: false,
        },
      ],
      minimum_revision: [
        {
          show_in_ui: true,
          enabled: false,
          value: "",
        },
      ],
      firmware_revision: [
        {
          show_in_ui: true,
          enabled: true,
          value: false,
        },
      ],
      build_options: [
        {
          show_in_ui: true,
          enabled: false,
          value: "",
        },
      ],
      Sections: [
        {
          warehouses: true,
          kitting: false,
          eco: true,
          substitution: false,
          bom: false,
        },
      ],
    },
    warehouseData: {
      bu_key: 0,
      ware: "",
      business: "",
      whse_type: "",
      tab1: [
        {
          enabled: true,
          prompts: [
            {
              whse_owner: [
                {
                  company: "",
                  address1: "",
                  city: "",
                  state: "",
                  country: "",
                },
              ],
              whse_location: [
                {
                  company: "",
                  address1: "",
                  city: "",
                  state: "",
                  country: "",
                },
              ],
              avg_cost: false,
              lead_time: false,
              reorder_point: false,
              dsi: false,
            },
          ],
        },
      ],
      tab2: [
        {
          enabled: false,
          prompts: [
            {
              cra: false,
              dnc_rcv: false,
              ifir: false,
              ifir_days: false,
              returns_to_scrap: false,
              dnc_bypass_scrap: false,
              initial_test: false,
              iw_test_path: false,
              ow_test_path: false,
              final_test_pre: false,
              ow_repair: false,
              iw_repair: false,
              waiting_for_parts: false,
              firmware: false,
              final_test_post: false,
              engineering_eval: false,
              cosmetic_insp: false,
              audit: false,
              audit_sampling_rate: false,
              harvest: false,
            },
          ],
        },
      ],
    },
    warehouseFields: {
      tab1: [
        {
          avg_cost: 0,
          dsi: 0,
          lead_time: 0,
          reorder_point: 0,
        },
      ],
      tab2: [
        {
          cra: false,
          initial_test: false,
          dnc_rcv: false,
          iw_test_path: "",
          ow_test_path: "",
          ifir: false,
          final_test_pre: false,
          ifir_days: 0,
          ow_repair: false,
          iw_repair: false,
          returns_to_scrap: false,
          waiting_for_parts: false,
          dnc_bypass_scrap: false,
          firmware: false,
          final_test_post: false,
          engineering_eval: false,
          cosmetic_insp: false,
          audit: false,
          audit_sampling_rate: 0,
          harvest: false,
        },
      ],
    },
    headers: [
      {
        text: "Warehouse",
        align: "start",
        value: "ware",
        class: "primary customwhite--text",
      },
      {
        text: "Business",
        value: "business",
        class: "primary customwhite--text",
      },
      {
        text: "Warehouse Type",
        value: "whse_type",
        class: "primary customwhite--text",
      },
      {
        text: "Warehouse Location",
        value: "whse_location",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "Actions",
        class: "primary customwhite--text",
      },
    ],
    headersSubstitution: [
      {
        text: "Part Number",
        align: "start",
        value: "partNumber",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "description",
        class: "primary customwhite--text",
      },
      {
        text: "Warehouse",
        value: "ware",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "Actions",
        class: "primary customwhite--text",
      },
    ],
    headersMemberPart: [
      {
        text: "Member Part#",
        align: "start",
        value: "part_num",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "part_desc",
        class: "primary customwhite--text",
      },
      {
        text: "Quantity",
        value: "qty",
        class: "primary customwhite--text",
      },
      {
        text: "Is Primary Part",
        value: "primary_pn",
        class: "primary customwhite--text",
      },
      {
        text: "Status",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "Actions",
        class: "primary customwhite--text",
      },
    ],
    partJSON: [
      {
        part_id: 0,
        proj_key: 0,
        part_number: "",
        part_description: "",
        active: true,
        ptype_key: 0,
        class_key: 0,
        oem_key: 0,
        odm_key: 0,
        oem_part: "",
        model: "",
        oem_model: "",
        uom_key: 0,
        bin_type_key: 0,
        bin_limit: 0,
        packout_ref: "",
        box_label_size_key: 0,
        height: 0,
        width: 0,
        length: 0,
        weight: 0,
        unit_cost: 0,
        sale_price: 0,
        exchange_price: 0,
        repair_price: 0,
        so_warranty_days: 0,
        fgi_dsi_cutoff: 0,
        oem_dsi_cutoff: 0,
        oem_parts_per_skid: 0,
        cf_buffer_qty: 0,
        sales_orders: true,
        so_straight_sale: true,
        so_advanced_exchange: true,
        so_exchange: true,
        so_customer_repair: true,
        so_straight_return: true,
        purchase_orders: true,
        po_straight_purchase: true,
        po_advanced_exchange: true,
        po_exchange: true,
        po_stock_repair: true,
        po_return: true,
        bcn: true,
        auto_generate_bcn: false,
        auto_generated_prefix: "",
        serial_number: true,
        gobi: true,
        esn_imei: true,
        iccid: true,
        box_label: true,
        print_comment_sheet: true,
        prompt_for_new: true,
        third_strike: true,
        third_strike_visit: 0,
        return_waybill_required: true,
        revision_level: true,
        minimum_revision: "",
        firmware_revision: true,
        build: [],
        warehouse: [],
        kit: "",
        eco: "",
        subs: [],
        bom: [],
        user_key: EncryptUtility.localStorageDecrypt("userID"),
      },
    ],
    buildOptions: [],
    numberRule: [(v) => /^\d*\.?\d*$/.test(v) || "Only numbers are allowed"],
    savePart: true,
    savePartDisable: true,
    fab: false,
    maxInputValue: [(v) => parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647"],
    keywords: ["PLASMA TV", "TV", "%DISPLAY%", "%MONITOR%", "PROJECTOR", "LCD", "LCD TV"],
    matchingClassIds: [],
    displaySizeVal: 0,
    displaySizeShow: false,
  }),
  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.subPageUrl = data.subPageUrl;
    this.appPageName = data.appPageName;
    let countryData = await commonService.countryListNew(this.userId, "", 1, "get");
    this.countryList = countryData.CountryInfo;
  },
  //Refresh  Warning
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  //Refresh to check if Router is changes
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (window.confirm("Leave without saving?")) {
        next();
      } else {
        return false;
      }
    }
    next();
  },
  mounted() {
    this.resetFunction();
    this.getProject();
  },
  methods: {
    //to check if its a number of not
    isNumber(event, loc) {
      if (loc == "number") {
        let value = Utility.isNumber(event)
        return value
      }
      else {
        let value = Utility.isNumberWithPeriod(event)
        return value
      }
    },
    // Scroll Check method
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    // To go to the top
    toTop() {
      this.$vuetify.goTo(0);
    },
    // Parent navgation
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
    },
    //Reset Function
    resetFunction() {
      this.savePartDisable = true;
      this.kitDuplicate = 0;
      this.projectBrinks = false;
      this.kitSearched = true;
      this.kitBundlePartDis = true;
      this.memberPartKey = 0;
      this.saveKitBundle = false;
      this.editKitBundle = false;
      this.kitBundlePrimaryPart = false;
      this.kitBundleAddOn = false;
      this.kitBundleQty = 1;
      this.kitBundleActive = false;
      this.showKitBundleDetails = false;
      this.kitBundleList = [];
      this.kitBundleDetails = false;
      this.kitPartSearch = false;
      this.kitSearch = "";
      this.kitBundleDelete = false;
      this.kitBOMDialog = false;
      this.listMemberPartDetails = [];
      this.search = "";
      this.searchDisabled = true;
      this.subPartDelDialog = false;
      this.warehouseDelDialog = false;
      this.tab = null;
      this.deleteWarehouse = false;
      this.subPartDelete = false;
      this.wareDuplicate = 0;
      this.subDuplicate = 0;
      this.buildOptions = [];
      this.minRevLevelValue = "";
      this.minRevLevelShowInUI = true;
      this.minRevLevelEnabled = true;
      this.thirdStrikeValue = false;
      this.thirdStrikeShowInUI = true;
      this.thirdStrikeEnabled = true;
      this.enableSalesOrderValue = false;
      this.enableSalesOrderShowInUI = true;
      this.enableSalesOrderEnabled = true;
      this.soStraightSaleValue = false;
      this.soStraightSaleShowInUI = true;
      this.soStraightSaleEnabled = true;
      this.soAdvancedExchangeValue = false;
      this.soAdvancedExchangeShowInUI = true;
      this.soAdvancedExchangeEnabled = true;
      this.soExchangeValue = false;
      this.soExchangeShowInUI = true;
      this.soExchangeEnabled = true;
      this.soCustRepairValue = false;
      this.soCustRepairShowInUI = true;
      this.soCustRepairEnabled = true;
      this.soStraightReturnValue = false;
      this.soStraightReturnShowInUI = true;
      this.soStraightReturnEnabled = true;
      this.enablePurchaseOrdersValue = false;
      this.enablePurchaseOrdersShowInUI = true;
      this.enablePurchaseOrdersEnabled = true;
      this.poStraightPurchaseValue = false;
      this.poStraightPurchaseShowInUI = true;
      this.poStraightPurchaseEnabled = true;
      this.poAdvanceExchangeValue = false;
      this.poAdvanceExchangeShowInUI = true;
      this.poAdvanceExchangeEnabled = true;
      this.poExchangeValue = false;
      this.poExchangeShowInUI = true;
      this.poExchangeEnabled = true;
      this.poStockRepairValue = false;
      this.poStockRepairShowInUI = true;
      this.poStockRepairEnabled = true;
      this.poPOReturnValue = false;
      this.poPOReturnShowInUI = true;
      this.poPOReturnEnabled = true;
      this.bcnValue = false;
      this.bcnShowInUI = true;
      this.bcnEnabled = true;
      this.autoGenerateBCNValue = false;
      this.autoGenerateBCNShowInUI = true;
      this.autoGenerateBCNEnabled = true;
      this.autoGeneratedPrefixValue = "";
      this.autoGeneratedPrefixShowInUI = true;
      this.autoGeneratedPrefixEnabled = true;
      this.serialNoCapturedValue = false;
      this.serialNoCapturedShowInUI = true;
      this.serialNoCapturedEnabled = true;
      this.gobiValue = false;
      this.gobiShowInUI = true;
      this.gobiEnabled = true;
      this.esnIMEIValue = false;
      this.esnIMEIShowInUI = true;
      this.esnIMEIEnabled = true;
      this.ICCIDCapturedValue = false;
      this.ICCIDCapturedShowInUI = true;
      this.ICCIDCapturedEnabled = true;
      this.boxLabelValue = false;
      this.boxLabelShowInUI = true;
      this.boxLabelEnabled = true;
      this.printCommentSheetValue = false;
      this.printCommentSheetShowInUI = true;
      this.printCommentSheetEnabled = true;
      this.promptForNewProductValue = false;
      this.promptForNewProductShowInUI = true;
      this.promptForNewProductEnabled = true;
      this.activateStrikeRuleValue = false;
      this.activateStrikeRuleShowInUI = true;
      this.activateStrikeRuleEnabled = true;
      this.returnWaybillLabelRequiredValue = false;
      this.returnWaybillLabelRequiredShowInUI = true;
      this.returnWaybillLabelRequiredEnabled = true;
      this.promptRevLevelValue = false;
      this.promptRevLevelShowInUI = true;
      this.promptRevLevelEnabled = true;
      this.promptFirmLevelValue = false;
      this.promptFirmLevelShowInUI = true;
      this.promptFirmLevelEnabled = true;
      this.continueBtnDisable = true;
      this.substitutionSaveDisabled = true;
      this.btnValShow = true;
      this.sequence = 0;
      this.saveSubstitutionDet = false;
      this.editSubstitutionDet = false;
      this.part_key = "";
      this.valPartNumFill = false;
      this.numberPart = "";
      this.descriptionPart = "";
      this.showPartDetails = false;
      this.substitutionList = [];
      this.partNumSearched = true;
      this.listSubstitutionDetails = [];
      this.partNumWarehouse = "";
      this.warehousePartNumList = [];
      this.partNumberSearch = "";
      this.substitutionPartListDialog = false;
      this.saveWarehouseData = false;
      this.editWarehouseData = false;
      this.partNumberChange = false;
      this.partDescChange = true;
      this.projectChange = true;
      this.partTypeChange = true;
      this.wareSelected = false;
      this.showExpansionTables = false;
      this.iwTestPathList = [];
      this.owTestPathList = [];
      this.warehouseDataAvlbl = false;
      this.listWarehouse = [];
      this.warehouseDialog = false;
      this.binTypeList = [];
      this.oemModelList = [];
      this.modelList = [];
      this.selectedPartType = "";
      this.selectedProject = "";
      this.warehouseList = [];
      this.ecoInfo = "";
      this.cosmetic = "";
      this.rlpPartNum = "";
      this.isPartEditAddFormValid = false;
      this.promptFirmWareLevel = false;
      this.buildOptionsList = [];
      this.isFormValid = false;
      this.projectList = [];
      this.partTypeList = [];
      this.enableValidate = true;
      this.partNumberValidated = false;
      this.partClassList = [];
      this.partDesc = "";
      this.partNumber = "";
      this.oemList = [];
      this.odmList = [];
      this.unitOfMeasureList = [];
      this.boxLabelSizeList = [];
      this.selectedCountry = "";
      this.resetJSONArray();
      this.resetWarehouseJSONArray();
      this.resetWarehouseFieldJSONArray();
      this.$refs.partsSearchForm.resetValidation();
      this.$refs.partsEditAddForm.resetValidation();
      this.getProject();
    },
    //Reset JSON Arrays
    resetWarehouseFieldJSONArray() {
      //Warehouse Fields
      this.warehouseFields.tab1[0].avg_cost = 0;
      this.warehouseFields.tab1[0].dsi = 0;
      this.warehouseFields.tab1[0].lead_time = 0;
      this.warehouseFields.tab1[0].reorder_point = 0;
      this.CRAValue = false;
      this.CRAShowInUI = true;
      this.CRAEnabled = true;
      this.DNCRCVValue = false;
      this.DNCRCVShowInUI = true;
      this.DNCRCVEnabled = true;
      this.IFIRValue = false;
      this.IFIRShowInUI = true;
      this.IFIREnabled = true;
      this.IFIRDaysValue = 0;
      this.IFIRDaysShowInUI = true;
      this.IFIRDaysEnabled = false;
      this.returnsToScrapValue = false;
      this.returnsToScrapShowInUI = true;
      this.returnsToScrapEnabled = true;
      this.DNCBypassScrapValue = false;
      this.DNCBypassScrapShowInUI = true;
      this.DNCBypassScrapEnabled = true;
      this.initialTestTriageValue = false;
      this.initialTestTriageShowInUI = true;
      this.initialTestTriageEnabled = true;
      this.iwTestPathValue = false;
      this.iwTestPathShowInUI = true;
      this.iwTestPathEnabled = false;
      this.owTestPathValue = false;
      this.owTestPathShowInUI = true;
      this.owTestPathEnabled = false;
      this.finalTestPreValue = false;
      this.finalTestPreShowInUI = true;
      this.finalTestPreEnabled = true;
      this.owRepairValue = false;
      this.owRepairShowInUI = true;
      this.owRepairEnabled = true;
      this.iwRepairValue = false;
      this.iwRepairShowInUI = true;
      this.iwRepairEnabled = true;
      this.waitingForPartsValue = false;
      this.waitingForPartsShowInUI = true;
      this.waitingForPartsEnabled = true;
      this.firmwareValue = false;
      this.firmwareShowInUI = true;
      this.firmwareEnabled = true;
      this.finalTestPostValue = false;
      this.finalTestPostShowInUI = true;
      this.finalTestPostEnabled = true;
      this.engineeringEvalValue = false;
      this.engineeringEvalShowInUI = true;
      this.engineeringEvalEnabled = true;
      this.wipeSanitizeValue = false;
      this.wipeSanitizeShowInUI = true;
      this.wipeSanitizeEnabled = true;
      this.cosmeticInspectionValue = false;
      this.cosmeticInspectionShowInUI = true;
      this.cosmeticInspectionEnabled = true;
      this.auditValue = false;
      this.auditShowInUI = true;
      this.auditEnabled = true;
      this.auditSamplingRateValue = "";
      this.auditSamplingRateShowInUI = true;
      this.auditSamplingRateEnabled = false;
      this.harvestValue = false;
      this.harvestShowInUI = true;
      this.harvestEnabled = true;
    },
    resetWarehouseJSONArray() {
      //Warehouse Enabling values
      this.warehouseData.bu_key = 0;
      this.warehouseData.ware = "";
      this.warehouseData.business = "";
      this.warehouseData.whse_type = "";
      this.warehouseData.tab1[0].enabled = false;
      this.warehouseData.tab1[0].prompts[0].whse_owner[0].company = "";
      this.warehouseData.tab1[0].prompts[0].whse_owner[0].address1 = "";
      this.warehouseData.tab1[0].prompts[0].whse_owner[0].city = "";
      this.warehouseData.tab1[0].prompts[0].whse_owner[0].state = "";
      this.warehouseData.tab1[0].prompts[0].whse_owner[0].country = "";
      this.warehouseData.tab1[0].prompts[0].whse_location[0].company = "";
      this.warehouseData.tab1[0].prompts[0].whse_location[0].address1 = "";
      this.warehouseData.tab1[0].prompts[0].whse_location[0].city = "";
      this.warehouseData.tab1[0].prompts[0].whse_location[0].state = "";
      this.warehouseData.tab1[0].prompts[0].whse_location[0].country = "";
      this.warehouseData.tab1[0].prompts[0].avg_cost = "";
      this.warehouseData.tab1[0].prompts[0].lead_time = "";
      this.warehouseData.tab1[0].prompts[0].reorder_point = "";
      this.warehouseData.tab1[0].prompts[0].dsi = "";
      this.warehouseData.tab2[0].enabled = false;
      this.warehouseData.tab2[0].prompts[0].cra = false;
      this.warehouseData.tab2[0].prompts[0].initial_test = false;
      this.warehouseData.tab2[0].prompts[0].dnc_rcv = false;
      this.warehouseData.tab2[0].prompts[0].ifir = false;
      this.warehouseData.tab2[0].prompts[0].ifir_days = false;
      this.warehouseData.tab2[0].prompts[0].returns_to_scrap = false;
      this.warehouseData.tab2[0].prompts[0].dnc_bypass_scrap = false;
      this.warehouseData.tab2[0].prompts[0].initial_test = false;
      this.warehouseData.tab2[0].prompts[0].iw_test_path = false;
      this.warehouseData.tab2[0].prompts[0].ow_test_path = false;
      this.warehouseData.tab2[0].prompts[0].final_test_pre = false;
      this.warehouseData.tab2[0].prompts[0].ow_repair = false;
      this.warehouseData.tab2[0].prompts[0].iw_repair = false;
      this.warehouseData.tab2[0].prompts[0].waiting_for_parts = false;
      this.warehouseData.tab2[0].prompts[0].firmware = false;
      this.warehouseData.tab2[0].prompts[0].final_test_post = false;
      this.warehouseData.tab2[0].prompts[0].engineering_eval = false;
      this.warehouseData.tab2[0].prompts[0].cosmetic_insp = false;
      this.warehouseData.tab2[0].prompts[0].audit = false;
      this.warehouseData.tab2[0].prompts[0].audit_sampling_rate = false;
      this.warehouseData.tab2[0].prompts[0].harvest = false;
    },
    resetJSONArray() {
      //Form Data
      this.formData.part_class[0].show_in_ui = true;
      this.formData.part_class[0].enabled = false;
      this.formData.part_class[0].value = "";
      this.formData.oem[0].show_in_ui = true;
      this.formData.oem[0].enabled = false;
      this.formData.oem[0].value = "";
      this.formData.odm[0].show_in_ui = true;
      this.formData.odm[0].enabled = false;
      this.formData.odm[0].value = "";
      this.formData.oem_part[0].show_in_ui = true;
      this.formData.oem_part[0].enabled = false;
      this.oemPartNum = "";
      this.formData.model[0].show_in_ui = true;
      this.formData.model[0].enabled = false;
      this.formData.model[0].value = "";
      this.formData.oem_model[0].show_in_ui = true;
      this.formData.oem_model[0].enabled = false;
      this.formData.oem_model[0].value = "";
      this.formData.unit_of_measure[0].show_in_ui = true;
      this.formData.unit_of_measure[0].enabled = false;
      this.formData.unit_of_measure[0].value = "";
      this.formData.bin_type[0].show_in_ui = true;
      this.formData.bin_type[0].enabled = false;
      this.formData.bin_type[0].value = "";
      this.formData.bin_limit[0].show_in_ui = true;
      this.formData.bin_limit[0].enabled = false;
      this.formData.bin_limit[0].value = 0;
      this.formData.packout_ref[0].show_in_ui = true;
      this.formData.packout_ref[0].enabled = false;
      this.formData.packout_ref[0].value = "";
      this.formData.box_label_size[0].show_in_ui = true;
      this.formData.box_label_size[0].enabled = false;
      this.formData.box_label_size[0].value = "";
      this.formData.height_width_length[0].show_in_ui = true;
      this.formData.height_width_length[0].enabled = false;
      this.formData.height_width_length[0].value = 0;
      this.formData.weight[0].show_in_ui = true;
      this.formData.weight[0].enabled = false;
      this.formData.weight[0].value = 0;
      this.formData.daily_usage[0].show_in_ui = true;
      this.formData.daily_usage[0].enabled = false;
      this.formData.daily_usage[0].value = 0;
      this.formData.demand[0].show_in_ui = true;
      this.formData.demand[0].enabled = false;
      this.formData.demand[0].value = 0;
      this.formData.unit_cost[0].show_in_ui = true;
      this.formData.unit_cost[0].enabled = false;
      this.formData.unit_cost[0].value = 0;
      this.formData.sale_price[0].show_in_ui = true;
      this.formData.sale_price[0].enabled = false;
      this.formData.sale_price[0].value = 0;
      this.formData.exchange_price[0].show_in_ui = true;
      this.formData.exchange_price[0].enabled = false;
      this.formData.exchange_price[0].value = 0;
      this.formData.repair_price[0].show_in_ui = true;
      this.formData.repair_price[0].enabled = false;
      this.formData.repair_price[0].value = 0;
      this.formData.so_warranty_days[0].show_in_ui = true;
      this.formData.so_warranty_days[0].enabled = false;
      this.formData.so_warranty_days[0].value = 0;
      this.formData.fgi_dsi_cutoff[0].show_in_ui = true;
      this.formData.fgi_dsi_cutoff[0].enabled = false;
      this.formData.fgi_dsi_cutoff[0].value = 0;
      this.formData.oem_dsi_cutoff[0].show_in_ui = true;
      this.formData.oem_dsi_cutoff[0].enabled = false;
      this.formData.oem_dsi_cutoff[0].value = 0;
      this.formData.oem_parts_per_skid[0].show_in_ui = true;
      this.formData.oem_parts_per_skid[0].enabled = false;
      this.formData.oem_parts_per_skid[0].value = 0;
      this.formData.cf_buffer_qty[0].show_in_ui = true;
      this.formData.cf_buffer_qty[0].enabled = false;
      this.formData.cf_buffer_qty[0].value = 0;
      this.formData.build_options[0].show_in_ui = true;
      this.formData.build_options[0].enabled = false;
      this.formData.build_options[0].value = "";
      this.formData.Sections[0].warehouses = false;
      this.formData.Sections[0].kitting = false;
      this.formData.Sections[0].eco = false;
      this.formData.Sections[0].substitution = false;
      this.formData.Sections[0].bom = false;
    },
    //Project Details
    async getProject() {
      let projectData = await commonService.getProject("get", this.userId);
      this.projectList = projectData.ProjectInfo;
    },
    //Part Number Change
    changePartNumber() {
      this.partNumberChange = false;
      this.partDescChange = false;
    },
    //Part Description Change
    changePartDesc() {
      this.partDescChange = false;
      this.projectChange = false;
    },
    //On project selection
    projectSelected() {
      this.getPartType();
      this.selectedPartType = "";
      this.partTypeChange = false;
    },
    //Part Type details on selection of project
    async getPartType() {
      let isAdd = true; // Need to test from API - kuldeep(if value is required from where this value has to be brought from UI?)
      let partTypeData = await PartService.getPartType("get", this.selectedProject, isAdd);
      this.partTypeList = partTypeData;
    },
    //On Part Type Change
    partTypeSelected() {
      this.enableValidate = false;
    },
    //Validate button click event
    async onClickValidate() {
      if (this.$refs.partsSearchForm.validate()) {
        let validateObj = {
          proj_key: parseInt(this.selectedProject),
          ptype_key: parseInt(this.selectedPartType),
          part_number: this.partNumber,
        };
        let orderTypeData = await PartService.postValidatePartNum("post", validateObj);
        if (orderTypeData) {
          this.partTypeChange = true;
          this.projectChange = true;
          this.partNumberChange = true;
          this.partDescChange = true;
          this.btnValShow = false;
          this.partNumberValidated = true;
          this.enableValidate = true;
          this.enableSalesOrderValue = orderTypeData[0].sales_orders[0].value;
          this.enableSalesOrderShowInUI = orderTypeData[0].sales_orders[0].show_in_ui;
          this.enableSalesOrderEnabled = orderTypeData[0].sales_orders[0].enabled;
          this.soStraightSaleValue = orderTypeData[0].so_straight_sale[0].value;
          this.soStraightSaleShowInUI = orderTypeData[0].so_straight_sale[0].show_in_ui;
          this.soStraightSaleEnabled = orderTypeData[0].so_straight_sale[0].enabled;
          this.soAdvancedExchangeValue = orderTypeData[0].so_advanced_exchange[0].value;
          this.soAdvancedExchangeShowInUI = orderTypeData[0].so_advanced_exchange[0].show_in_ui;
          this.soAdvancedExchangeEnabled = orderTypeData[0].so_advanced_exchange[0].enabled;
          this.soExchangeValue = orderTypeData[0].so_exchange[0].value;
          this.soExchangeShowInUI = orderTypeData[0].so_exchange[0].show_in_ui;
          this.soExchangeEnabled = orderTypeData[0].so_exchange[0].enabled;
          this.soCustRepairValue = orderTypeData[0].so_customer_repair[0].value;
          this.soCustRepairShowInUI = orderTypeData[0].so_customer_repair[0].show_in_ui;
          this.soCustRepairEnabled = orderTypeData[0].so_customer_repair[0].enabled;
          this.soStraightReturnValue = orderTypeData[0].so_straight_return[0].value;
          this.soStraightReturnShowInUI = orderTypeData[0].so_straight_return[0].show_in_ui;
          this.soStraightReturnEnabled = orderTypeData[0].so_straight_return[0].enabled;
          this.enablePurchaseOrdersValue = orderTypeData[0].purchase_orders[0].value;
          this.enablePurchaseOrdersShowInUI = orderTypeData[0].purchase_orders[0].show_in_ui;
          this.enablePurchaseOrdersEnabled = orderTypeData[0].purchase_orders[0].enabled;
          this.poStraightPurchaseValue = orderTypeData[0].po_straight_purchase[0].value;
          this.poStraightPurchaseShowInUI = orderTypeData[0].po_straight_purchase[0].show_in_ui;
          this.poStraightPurchaseEnabled = orderTypeData[0].po_straight_purchase[0].enabled;
          this.poAdvanceExchangeValue = orderTypeData[0].po_advanced_exchange[0].value;
          this.poAdvanceExchangeShowInUI = orderTypeData[0].po_advanced_exchange[0].show_in_ui;
          this.poAdvanceExchangeEnabled = orderTypeData[0].po_advanced_exchange[0].enabled;
          this.poExchangeValue = orderTypeData[0].po_exchange[0].value;
          this.poExchangeShowInUI = orderTypeData[0].po_exchange[0].show_in_ui;
          this.poExchangeEnabled = orderTypeData[0].po_exchange[0].enabled;
          this.poStockRepairValue = orderTypeData[0].po_stock_repair[0].value;
          this.poStockRepairShowInUI = orderTypeData[0].po_stock_repair[0].show_in_ui;
          this.poStockRepairEnabled = orderTypeData[0].po_stock_repair[0].enabled;
          this.poPOReturnValue = orderTypeData[0].po_return[0].value;
          this.poPOReturnShowInUI = orderTypeData[0].po_return[0].show_in_ui;
          this.poPOReturnEnabled = orderTypeData[0].po_return[0].enabled;
          this.bcnValue = orderTypeData[0].bcn[0].value;
          this.bcnShowInUI = orderTypeData[0].bcn[0].show_in_ui;
          this.bcnEnabled = orderTypeData[0].bcn[0].enabled;
          this.autoGenerateBCNValue = orderTypeData[0].auto_generate_bcn[0].value;
          this.autoGenerateBCNShowInUI = orderTypeData[0].auto_generate_bcn[0].show_in_ui;
          this.autoGenerateBCNEnabled = orderTypeData[0].auto_generate_bcn[0].enabled;
          this.autoGeneratedPrefixValue = orderTypeData[0].auto_generated_prefix[0].value;
          this.autoGeneratedPrefixShowInUI = orderTypeData[0].auto_generated_prefix[0].show_in_ui;
          this.autoGeneratedPrefixEnabled = orderTypeData[0].auto_generated_prefix[0].enabled;
          this.serialNoCapturedValue = orderTypeData[0].serial_number[0].value;
          this.serialNoCapturedShowInUI = orderTypeData[0].serial_number[0].show_in_ui;
          this.serialNoCapturedEnabled = orderTypeData[0].serial_number[0].enabled;
          this.gobiValue = orderTypeData[0].gobi[0].value;
          this.gobiShowInUI = orderTypeData[0].gobi[0].show_in_ui;
          this.gobiEnabled = orderTypeData[0].gobi[0].enabled;
          this.esnIMEIValue = orderTypeData[0].esn_imei[0].value;
          this.esnIMEIShowInUI = orderTypeData[0].esn_imei[0].show_in_ui;
          this.esnIMEIEnabled = orderTypeData[0].esn_imei[0].enabled;
          this.ICCIDCapturedValue = orderTypeData[0].iccid[0].value;
          this.ICCIDCapturedShowInUI = orderTypeData[0].iccid[0].show_in_ui;
          this.ICCIDCapturedEnabled = orderTypeData[0].iccid[0].enabled;
          this.boxLabelValue = orderTypeData[0].box_label[0].value;
          this.boxLabelShowInUI = orderTypeData[0].box_label[0].show_in_ui;
          this.boxLabelEnabled = orderTypeData[0].box_label[0].enabled;
          this.printCommentSheetValue = orderTypeData[0].print_comment_sheet[0].value;
          this.printCommentSheetShowInUI = orderTypeData[0].print_comment_sheet[0].show_in_ui;
          this.printCommentSheetEnabled = orderTypeData[0].print_comment_sheet[0].enabled;
          this.promptForNewProductValue = orderTypeData[0].prompt_for_new[0].value;
          this.promptForNewProductShowInUI = orderTypeData[0].prompt_for_new[0].show_in_ui;
          this.promptForNewProductEnabled = orderTypeData[0].prompt_for_new[0].enabled;
          this.activateStrikeRuleValue = orderTypeData[0].third_strike[0].value;
          this.activateStrikeRuleShowInUI = orderTypeData[0].third_strike[0].show_in_ui;
          this.activateStrikeRuleEnabled = orderTypeData[0].third_strike[0].enabled;
          this.returnWaybillLabelRequiredValue = orderTypeData[0].return_waybill_required[0].value;
          this.returnWaybillLabelRequiredShowInUI = orderTypeData[0].return_waybill_required[0].show_in_ui;
          this.returnWaybillLabelRequiredEnabled = orderTypeData[0].return_waybill_required[0].enabled;
          this.promptRevLevelValue = orderTypeData[0].revision_level[0].value;
          this.promptRevLevelShowInUI = orderTypeData[0].revision_level[0].show_in_ui;
          this.promptRevLevelEnabled = orderTypeData[0].revision_level[0].enabled;
          this.promptFirmLevelValue = orderTypeData[0].firmware_revision[0].value;
          this.promptFirmLevelShowInUI = orderTypeData[0].firmware_revision[0].show_in_ui;
          this.promptFirmLevelEnabled = orderTypeData[0].firmware_revision[0].enabled;
          this.thirdStrikeValue = orderTypeData[0].third_strike_visit[0].value;
          this.thirdStrikeShowInUI = orderTypeData[0].third_strike_visit[0].show_in_ui;
          this.thirdStrikeEnabled = orderTypeData[0].third_strike_visit[0].enabled;
          this.minRevLevelValue = orderTypeData[0].third_strike_visit[0].value;
          this.minRevLevelShowInUI = orderTypeData[0].third_strike_visit[0].show_in_ui;
          this.minRevLevelEnabled = orderTypeData[0].third_strike_visit[0].enabled;
          Object.assign(this.formData, orderTypeData[0]);
          this.getPartClass();
          this.getOEMDetails();
          this.getODMDetails();
          this.getModelDetails();
          this.getUOMDetails();
          this.getBinTypeDetails();
          this.getLabelSizeDetails();
          this.getBuildActionDetails();
        }
      }
    },
    //Dropdown data bringing API Calls - Start
    //Get Part Class
    async getPartClass() {
      let partClassData = await PartService.getPartClass("get", this.userId, this.selectedProject);
      this.matchingClassIds = this.compareClasses(partClassData, this.keywords);
      this.partClassList = partClassData;
    },
    //Comparing and getting the Part Class keys
    compareClasses(data, keywords) {
      const matchingIds = [];
      for (const item of data) {
        const itemClass = item.class.toUpperCase();
        let isMatch = false;
        for (const keyword of keywords) {
          const normalizedKeyword = keyword.toUpperCase();
          if (!isMatch && (itemClass === normalizedKeyword || itemClass.includes(normalizedKeyword))) {
            isMatch = true;
            break;
          }
        }
        if (isMatch) {
          matchingIds.push(item.class_id);
        }
      }
      return matchingIds;
    },
    //Whether to show display size or not
    showDisplaySize() {
      this.continueBtnDisable = false;
      let display = this.matchingClassIds.find((element) => element == this.formData.part_class[0].value);
      if (display !== undefined && display !== null) {
        this.displaySizeShow = true;
      } else {
        this.displaySizeShow = false;
        this.displaySizeVal = 0;
      }
    },
    //Get OEM Details
    async getOEMDetails() {
      let OEMData = await PartService.getOEMDetails("get", this.userId, this.selectedProject);
      this.oemList = OEMData;
    },
    //Get ODM Details
    async getODMDetails() {
      let ODMData = await PartService.getODMDetails("get", this.userId, this.selectedProject);
      this.odmList = ODMData;
    },
    //Get Model Details
    async getModelDetails() {
      let modelData = await PartService.getModelDetails("get", this.userId, this.selectedProject);
      if (modelData.message == "NA") {
        this.modelList = []
      }
      else
        this.modelList = modelData;
    },
    //Get OEM Model Details
    async getOEMModelDetails() {
      let OEMModelData = await PartService.getOEMModelDetails("get", this.userId, this.selectedProject);
      this.oemModelList = OEMModelData;
    },
    //Get UOM Details
    async getUOMDetails() {
      let UOMData = await PartService.getUOMDetails("get");
      this.unitOfMeasureList = UOMData;
    },
    //Get Bin Type Details
    async getBinTypeDetails() {
      let binTypeData = await PartService.getBinTypeDetails("get");
      this.binTypeList = binTypeData;
    },
    //Get Label Size Details
    async getLabelSizeDetails() {
      let labelSizeData = await PartService.getLabelSizeDetails("get");
      this.boxLabelSizeList = labelSizeData;
    },
    //Get Build Action Details
    async getBuildActionDetails() {
      let buildActionData = await PartService.getBuildActionDetails("get");
      this.buildOptionsList = buildActionData;
    },
    //Dropdown data bringing API Calls - End

    //Sales Order and Purchase Order checkboxes - Start
    //Enable Sales Order Selected
    async enableSalesOrderSelected(name, value) {
      let wareKey = 0; // Do not delete - CP
      let controlUIObj = {
        ptype_key: parseInt(this.selectedPartType),
        checkbox_name: name,
        checkbox_value: value,
        bcn: this.bcnValue,
        serial_number: this.serialNoCapturedValue,
        ware_key: parseInt(wareKey),
        iw_repair: this.iwRepairValue,
        ow_repair: this.owRepairValue,
        gobi: this.gobiValue,
        esn_imei: this.esnIMEIValue,
        iccid: this.ICCIDCapturedValue,
      };
      let controlUIData = await PartService.postControlUIDetails("post", controlUIObj);
      if (controlUIData[0] !== undefined) {
        if (controlUIData[0].so_advanced_exchange !== undefined) {
          this.soAdvancedExchangeValue = controlUIData[0].so_advanced_exchange[0].value;
          this.soAdvancedExchangeShowInUI = controlUIData[0].so_advanced_exchange[0].show_in_ui;
          this.soAdvancedExchangeEnabled = controlUIData[0].so_advanced_exchange[0].enabled;
        }
        if (controlUIData[0].so_customer_repair !== undefined) {
          this.soCustRepairValue = controlUIData[0].so_customer_repair[0].value;
          this.soCustRepairShowInUI = controlUIData[0].so_customer_repair[0].show_in_ui;
          this.soCustRepairEnabled = controlUIData[0].so_customer_repair[0].enabled;
        }
        if (controlUIData[0].so_exchange !== undefined) {
          this.soExchangeValue = controlUIData[0].so_exchange[0].value;
          this.soExchangeShowInUI = controlUIData[0].so_exchange[0].show_in_ui;
          this.soExchangeEnabled = controlUIData[0].so_exchange[0].enabled;
        }
        if (controlUIData[0].so_straight_return !== undefined) {
          this.soStraightReturnValue = controlUIData[0].so_straight_return[0].value;
          this.soStraightReturnShowInUI = controlUIData[0].so_straight_return[0].show_in_ui;
          this.soStraightReturnEnabled = controlUIData[0].so_straight_return[0].enabled;
        }
        if (controlUIData[0].so_straight_sale !== undefined) {
          this.soStraightSaleValue = controlUIData[0].so_straight_sale[0].value;
          this.soStraightSaleShowInUI = controlUIData[0].so_straight_sale[0].show_in_ui;
          this.soStraightSaleEnabled = controlUIData[0].so_straight_sale[0].enabled;
        }
      }
    },
    //Enable Purchase Order Selected
    async enablePurchaseOrderSelected(name, value) {
      let wareKey = 0; // Do not delete - CP
      let controlUIObj = {
        ptype_key: parseInt(this.selectedPartType),
        checkbox_name: name,
        checkbox_value: value,
        bcn: this.bcnValue,
        serial_number: this.serialNoCapturedValue,
        ware_key: parseInt(wareKey),
        iw_repair: this.iwRepairValue,
        ow_repair: this.owRepairValue,
        gobi: this.gobiValue,
        esn_imei: this.esnIMEIValue,
        iccid: this.ICCIDCapturedValue,
      };
      let controlUIData = await PartService.postControlUIDetails("post", controlUIObj);
      if (controlUIData[0] !== undefined) {
        if (controlUIData[0].po_advanced_exchange !== undefined) {
          this.poAdvanceExchangeValue = controlUIData[0].po_advanced_exchange[0].value;
          this.poAdvanceExchangeShowInUI = controlUIData[0].po_advanced_exchange[0].show_in_ui;
          this.poAdvanceExchangeEnabled = controlUIData[0].po_advanced_exchange[0].enabled;
        }
        if (controlUIData[0].po_exchange !== undefined) {
          this.poExchangeValue = controlUIData[0].po_exchange[0].value;
          this.poExchangeShowInUI = controlUIData[0].po_exchange[0].show_in_ui;
          this.poExchangeEnabled = controlUIData[0].po_exchange[0].enabled;
        }
        if (controlUIData[0].po_return !== undefined) {
          this.poPOReturnValue = controlUIData[0].po_return[0].value;
          this.poPOReturnShowInUI = controlUIData[0].po_return[0].show_in_ui;
          this.poPOReturnEnabled = controlUIData[0].po_return[0].enabled;
        }
        if (controlUIData[0].po_stock_repair !== undefined) {
          this.poStockRepairValue = controlUIData[0].po_stock_repair[0].value;
          this.poStockRepairShowInUI = controlUIData[0].po_stock_repair[0].show_in_ui;
          this.poStockRepairEnabled = controlUIData[0].po_stock_repair[0].enabled;
        }
        if (controlUIData[0].po_straight_purchase !== undefined) {
          this.poStraightPurchaseValue = controlUIData[0].po_straight_purchase[0].value;
          this.poStraightPurchaseShowInUI = controlUIData[0].po_straight_purchase[0].show_in_ui;
          this.poStraightPurchaseEnabled = controlUIData[0].po_straight_purchase[0].enabled;
        }
      }
    },
    //Sales Order and Purchase Order checkboxes - End

    //Close button on Warehouse and Substitution Part popup
    closeDialog() {
      this.kitBOMDialog = false;
      this.kitBundleDelDialog = false;
      this.partNumberSearch = "";
      this.partNumWarehouse = "";
      this.numberPart = "";
      this.descriptionPart = "";
      this.subPartDelDialog = false;
      this.warehouseDelDialog = false;
      this.valPartNumFill = false;
      this.partNumSearched = true;
      this.showPartDetails = false;
      this.wareSelected = false;
      this.warehouseDialog = false;
      this.substitutionPartListDialog = false;
    },

    //Warehouse - Start
    //New Warehouse button click event
    async newWareHouse() {
      this.tab = 0;
      this.deleteWarehouse = false;
      this.warehouseDialog = true;
      this.wareSelected = false;
      this.warehouseDataAvlbl = false;
      this.saveWarehouseData = false;
      this.editWarehouseData = false;
      this.selectedWarehouse = "";
      this.showSelectedWare = true;
      let warehouseObj = {
        user_key: parseInt(this.userId),
        proj_key: parseInt(this.selectedProject),
        part_key: 0,
        ptype_key: parseInt(this.selectedPartType),
      };
      let warehouseData = await PartService.postNewWarehouse("post", warehouseObj);
      this.listWarehouse = warehouseData;
    },
    //Once Warehouse is selected
    async warehouseSelected() {
      if (this.warehouseList.length > 0) {
        this.wareDuplicate = this.warehouseList.find((elem) => elem.ware_id == this.selectedWarehouse);
      }
      if (this.wareDuplicate == 0 || this.wareDuplicate == undefined) {
        let warehouseSetupObj = {
          ware_key: parseInt(this.selectedWarehouse),
          proj_key: parseInt(this.selectedProject),
          ptype_key: parseInt(this.selectedPartType),
          isSerialized: this.serialNoCapturedValue || this.bcnValue,
        };
        let warehouseSetupData = await PartService.postWarehouseSetup("post", warehouseSetupObj);
        if (warehouseSetupData) {
          this.showSelectedWare = false;
          this.resetWarehouseFieldJSONArray();
          this.wareSelected = true;
          this.warehouseDataAvlbl = true;
          this.saveWarehouseData = true;
          this.editWarehouseData = false;
          Object.assign(this.warehouseData, warehouseSetupData[0]);
          this.getTestPathDetails();
        }
      } else {
        this.selectedWarehouse = "";
        this.wareDuplicate = 0;
        let Alert = {
          type: "error",
          isShow: true,
          message: "Warehouse already used.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Get Test paths
    async getTestPathDetails() {
      let testPathData = await PartService.getTestPathDetails("get");
      this.iwTestPathList = testPathData.iw_test_path;
      this.owTestPathList = testPathData.ow_test_path;
    },
    //Show Warehouse and other expansion tables
    showOtherDetails() {
      if (this.$refs.partsEditAddForm.validate()) {
        this.showExpansionTables = true;
        this.continueBtnDisable = true;
        this.savePartDisable = false;
      }
    },
    //Save Warehouse Info
    saveWarehouseInfo() {
      if (this.warehouseList.length > 0) {
        this.searchDisabled = false;
      }
      this.wareDuplicate = 0;
      let sequence = this.warehouseList.length + 1;
      let warehouseListObj = {
        sequence: sequence,
        ware_id: this.selectedWarehouse,
        bu_key: this.warehouseData.bu_key,
        ware: this.warehouseData.ware,
        business: this.warehouseData.business,
        whse_type: this.warehouseData.whse_type,
        whse_owner: this.warehouseData.tab1[0].prompts[0].whse_owner,
        whse_location: this.warehouseData.tab1[0].prompts[0].whse_location,
        warehouse_data: this.warehouseData,
        avg_cost: this.warehouseFields.tab1[0].avg_cost,
        dsi: this.warehouseFields.tab1[0].dsi,
        lead_time: this.warehouseFields.tab1[0].lead_time,
        reorder_point: this.warehouseFields.tab1[0].reorder_point,
        cra: this.CRAValue,
        initial_test: this.initialTestTriageValue,
        dnc_rcv: this.DNCRCVValue,
        iw_test_path: this.iwTestPathValue,
        ow_test_path: this.owTestPathValue,
        ifir: this.IFIRValue,
        final_test_pre: this.finalTestPreValue,
        ifir_days: this.IFIRDaysValue,
        ow_repair: this.owRepairValue,
        iw_repair: this.iwRepairValue,
        returns_to_scrap: this.returnsToScrapValue,
        waiting_for_parts: this.waitingForPartsValue,
        dnc_bypass_scrap: this.DNCBypassScrapValue,
        firmware: this.firmwareValue,
        final_test_post: this.finalTestPostValue,
        engineering_eval: this.engineeringEvalValue,
        wipe_sanitize: this.wipeSanitizeValue,
        cosmetic_insp: this.cosmeticInspectionValue,
        audit: this.auditValue,
        audit_sampling_rate: this.auditSamplingRateValue,
        harvest: this.harvestValue,
      };
      this.warehouseDialog = false;
      this.warehouseList.push(warehouseListObj);
    },
    //Edit Warehouse details
    async warehouseEdit(item) {
      this.tab = 0;
      if (this.warehouseList.length == 1) {
        this.deleteWarehouse = false;
      } else if (this.warehouseList.length > 1) {
        this.deleteWarehouse = true;
      }
      this.selectedWarehouse = item.ware_id;
      let warehouseSetupObj = {
        ware_key: parseInt(this.selectedWarehouse),
        proj_key: parseInt(this.selectedProject),
        ptype_key: parseInt(this.selectedPartType),
        isSerialized: this.serialNoCapturedValue || this.bcnValue,
      };
      let warehouseSetupData = await PartService.postWarehouseSetup("post", warehouseSetupObj);
      if (warehouseSetupData) {
        Object.assign(this.warehouseData, warehouseSetupData[0]);
      }

      //Warehouse Data assigning
      this.warehouseData.bu_key = item.bu_key;
      this.warehouseData.ware = item.ware;
      this.warehouseData.business = item.business;
      this.warehouseData.whse_type = item.whse_type;
      this.warehouseData.tab1[0].enabled = item.warehouse_data.tab1[0].enabled;
      this.warehouseData.tab1[0].prompts[0].whse_owner[0].company = item.warehouse_data.tab1[0].prompts[0].whse_owner[0].company;
      this.warehouseData.tab1[0].prompts[0].whse_owner[0].address1 = item.warehouse_data.tab1[0].prompts[0].whse_owner[0].address1;
      this.warehouseData.tab1[0].prompts[0].whse_owner[0].city = item.warehouse_data.tab1[0].prompts[0].whse_owner[0].city;
      this.warehouseData.tab1[0].prompts[0].whse_owner[0].state = item.warehouse_data.tab1[0].prompts[0].whse_owner[0].state;
      this.warehouseData.tab1[0].prompts[0].whse_owner[0].country = item.warehouse_data.tab1[0].prompts[0].whse_owner[0].country;
      this.warehouseData.tab1[0].prompts[0].whse_location[0].company = item.warehouse_data.tab1[0].prompts[0].whse_owner[0].company;
      this.warehouseData.tab1[0].prompts[0].whse_location[0].address1 = item.warehouse_data.tab1[0].prompts[0].whse_owner[0].address1;
      this.warehouseData.tab1[0].prompts[0].whse_location[0].city = item.warehouse_data.tab1[0].prompts[0].whse_owner[0].city;
      this.warehouseData.tab1[0].prompts[0].whse_location[0].state = item.warehouse_data.tab1[0].prompts[0].whse_owner[0].state;
      this.warehouseData.tab1[0].prompts[0].whse_location[0].country = item.warehouse_data.tab1[0].prompts[0].whse_owner[0].country;
      this.warehouseData.tab1[0].prompts[0].avg_cost = item.warehouse_data.tab1[0].prompts[0].avg_cost;
      this.warehouseData.tab1[0].prompts[0].lead_time = item.warehouse_data.tab1[0].prompts[0].lead_time;
      this.warehouseData.tab1[0].prompts[0].reorder_point = item.warehouse_data.tab1[0].prompts[0].reorder_point;
      this.warehouseData.tab1[0].prompts[0].dsi = item.warehouse_data.tab1[0].prompts[0].dsi;
      this.warehouseData.tab2[0].enabled = item.warehouse_data.tab2[0].enabled;
      this.CRAShowInUI = item.warehouse_data.tab2[0].prompts[0].cra;
      this.DNCRCVShowInUI = item.warehouse_data.tab2[0].prompts[0].dnc_rcv;
      this.IFIRShowInUI = item.warehouse_data.tab2[0].prompts[0].ifir;
      this.IFIRDaysShowInUI = item.warehouse_data.tab2[0].prompts[0].ifir_days;
      this.returnsToScrapShowInUI = item.warehouse_data.tab2[0].prompts[0].returns_to_scrap;
      this.DNCBypassScrapShowInUI = item.warehouse_data.tab2[0].prompts[0].dnc_bypass_scrap;
      this.initialTestTriageShowInUI = item.warehouse_data.tab2[0].prompts[0].initial_test;
      this.iwTestPathShowInUI = item.warehouse_data.tab2[0].prompts[0].iw_test_path;
      this.owTestPathShowInUI = item.warehouse_data.tab2[0].prompts[0].ow_test_path;
      this.finalTestPreShowInUI = item.warehouse_data.tab2[0].prompts[0].final_test_pre;
      this.owRepairShowInUI = item.warehouse_data.tab2[0].prompts[0].ow_repair;
      this.iwRepairShowInUI = item.warehouse_data.tab2[0].prompts[0].iw_repair;
      this.waitingForPartsShowInUI = item.warehouse_data.tab2[0].prompts[0].waiting_for_parts;
      this.firmwareShowInUI = item.warehouse_data.tab2[0].prompts[0].firmware;
      this.finalTestPostShowInUI = item.warehouse_data.tab2[0].prompts[0].final_test_post;
      this.engineeringEvalShowInUI = item.warehouse_data.tab2[0].prompts[0].engineering_eval;
      this.cosmeticInspectionShowInUI = item.warehouse_data.tab2[0].prompts[0].cosmetic_insp;
      this.auditShowInUI = item.warehouse_data.tab2[0].prompts[0].audit;
      this.auditSamplingRateShowInUI = item.warehouse_data.tab2[0].prompts[0].audit_sampling_rate;
      this.harvestShowInUI = item.warehouse_data.tab2[0].prompts[0].harvest;

      //Warehouse Field Data assigning
      this.warehouseFields.tab1[0].avg_cost = item.avg_cost;
      this.warehouseFields.tab1[0].dsi = item.dsi;
      this.warehouseFields.tab1[0].lead_time = item.lead_time;
      this.warehouseFields.tab1[0].reorder_point = item.reorder_point;
      this.CRAValue = item.cra;
      this.DNCRCVValue = item.dnc_rcv;
      this.iwTestPathValue = item.iw_test_path;
      this.owTestPathValue = item.ow_test_path;
      this.IFIRValue = item.ifir;
      this.finalTestPreValue = item.final_test_pre;
      this.IFIRDaysValue = item.ifir_days;
      this.owRepairValue = item.ow_repair;
      this.iwRepairValue = item.iw_repair;
      this.returnsToScrapValue = item.returns_to_scrap;
      this.DNCBypassScrapValue = item.dnc_bypass_scrap;
      this.firmwareValue = item.firmware;
      this.finalTestPostValue = item.final_test_post;
      this.engineeringEvalValue = item.engineering_eval;
      this.wipeSanitizeValue = item.wipe_sanitize;
      this.cosmeticInspectionValue = item.cosmetic_insp;
      this.auditValue = item.audit;
      this.auditSamplingRateValue = item.audit_sampling_rate;
      this.harvestValue = item.harvest;
      this.warehouseDialog = true;
      this.wareSelected = true;
      this.warehouseDataAvlbl = true;
      this.saveWarehouseData = false;
      this.editWarehouseData = true;
    },
    //Save Edit details
    editWarehouseInfo() {
      this.warehouseList = this.warehouseList.map((x) =>
        x.ware_id == this.selectedWarehouse
          ? {
            ...x,
            bu_key: this.warehouseData.bu_key,
            ware: this.warehouseData.ware,
            business: this.warehouseData.business,
            whse_type: this.warehouseData.whse_type,
            whse_owner: this.warehouseData.tab1[0].prompts[0].whse_owner,
            whse_location: this.warehouseData.tab1[0].prompts[0].whse_location,
            warehouse_data: this.warehouseData,
            avg_cost: this.warehouseFields.tab1[0].avg_cost,
            dsi: this.warehouseFields.tab1[0].dsi,
            lead_time: this.warehouseFields.tab1[0].lead_time,
            reorder_point: this.warehouseFields.tab1[0].reorder_point,
            cra: this.CRAValue,
            initial_test: this.initialTestTriageValue,
            dnc_rcv: this.DNCRCVValue,
            iw_test_path: this.iwTestPathValue,
            ow_test_path: this.owTestPathValue,
            ifir: this.IFIRValue,
            final_test_pre: this.finalTestPreValue,
            ifir_days: this.IFIRDaysValue,
            ow_repair: this.owRepairValue,
            iw_repair: this.iwRepairValue,
            returns_to_scrap: this.returnsToScrapValue,
            waiting_for_parts: this.waitingForPartsValue,
            dnc_bypass_scrap: this.DNCBypassScrapValue,
            firmware: this.firmwareValue,
            final_test_post: this.finalTestPostValue,
            engineering_eval: this.engineeringEvalValue,
            wipe_sanitize: this.wipeSanitizeValue,
            cosmetic_insp: this.cosmeticInspectionValue,
            audit: this.auditValue,
            audit_sampling_rate: this.auditSamplingRateValue,
            harvest: this.harvestValue,
          }
          : x
      );
      this.warehouseDialog = false;
    },
    //Get Warehouse Details
    async getWarehouseDetails(part_key) {
      let warehouseData = await PartService.getWarehouseDetails("get", part_key);
      if (warehouseData.length !== undefined) {
        this.warehousePartNumList = warehouseData;
        this.partNumSearched = false;
        this.valPartNumFill = true;
      } else {
        this.partNumSearched = true;
        this.valPartNumFill = false;
      }
    },
    //Refresh Warehouse Dialog
    refreshWareDialog() {
      this.tab = 0;
      this.showSelectedWare = true;
      this.selectedWarehouse = "";
      this.wareDuplicate = 0;
      this.warehouseDataAvlbl = false;
      this.saveWarehouseData = false;
      this.editWarehouseData = false;
      this.wareSelected = false;
      this.resetWarehouseJSONArray();
    },
    //Refresh for the Edit of Warehouse popup
    refreshEditWareDialog() {
      this.tab = 0;
      this.showSelectedWare = false;
      this.warehouseDataAvlbl = true;
      this.saveWarehouseData = false;
      this.editWarehouseData = true;
      this.resetWarehouseFieldJSONArray();
    },
    //API for validation of checkboxes in Warehouse popup Routing
    async changeWarehouseRouting(name, value) {
      let controlUIObj = {
        ptype_key: parseInt(this.selectedPartType),
        checkbox_name: name,
        checkbox_value: value,
        bcn: this.bcnValue,
        serial_number: this.serialNoCapturedValue,
        ware_key: parseInt(this.selectedWarehouse),
        iw_repair: this.iwRepairValue,
        ow_repair: this.owRepairValue,
        gobi: this.gobiValue,
        esn_imei: this.esnIMEIValue,
        iccid: this.ICCIDCapturedValue,
      };
      let controlUIData = await PartService.postControlUIDetails("post", controlUIObj);
      if (controlUIData[0] !== undefined) {
        if (controlUIData[0].dnc_bypass_scrap !== undefined) {
          this.DNCBypassScrapValue = controlUIData[0].dnc_bypass_scrap[0].value;
          this.DNCRCVShowInUI = controlUIData[0].dnc_bypass_scrap[0].show_in_ui;
          this.DNCRCVEnabled = controlUIData[0].dnc_bypass_scrap[0].enabled;
        }
        if (controlUIData[0].ifir_days !== undefined) {
          this.IFIRDaysValue = controlUIData[0].ifir_days[0].value;
          this.IFIRDaysShowInUI = controlUIData[0].ifir_days[0].show_in_ui;
          this.IFIRDaysEnabled = controlUIData[0].ifir_days[0].enabled;
        }
        if (controlUIData[0].ifir !== undefined) {
          this.IFIRValue = controlUIData[0].ifir[0].value;
          this.IFIRShowInUI = controlUIData[0].ifir[0].show_in_ui;
          this.IFIREnabled = controlUIData[0].ifir[0].enabled;
        }
        if (controlUIData[0].cra !== undefined) {
          this.CRAValue = controlUIData[0].cra[0].value;
          this.CRAShowInUI = controlUIData[0].cra[0].show_in_ui;
          this.CRAEnabled = controlUIData[0].cra[0].enabled;
        }
        if (controlUIData[0].initial_test !== undefined) {
          this.initialTestTriageValue = controlUIData[0].initial_test[0].value;
          this.initialTestTriageShowInUI = controlUIData[0].initial_test[0].show_in_ui;
          this.initialTestTriageEnabled = controlUIData[0].initial_test[0].enabled;
        }
        if (controlUIData[0].iw_test_path !== undefined) {
          this.iwTestPathValue = controlUIData[0].iw_test_path[0].value;
          this.iwTestPathShowInUI = controlUIData[0].iw_test_path[0].show_in_ui;
          this.iwTestPathEnabled = controlUIData[0].iw_test_path[0].enabled;
        }
        if (controlUIData[0].ow_test_path !== undefined) {
          this.owTestPathValue = controlUIData[0].ow_test_path[0].value;
          this.owTestPathShowInUI = controlUIData[0].ow_test_path[0].show_in_ui;
          this.owTestPathEnabled = controlUIData[0].ow_test_path[0].enabled;
        }
        if (controlUIData[0].final_test_pre !== undefined) {
          this.finalTestPreValue = controlUIData[0].final_test_pre[0].value;
          this.finalTestPreShowInUI = controlUIData[0].final_test_pre[0].show_in_ui;
          this.finalTestPreEnabled = controlUIData[0].final_test_pre[0].enabled;
        }
        if (controlUIData[0].iw_repair !== undefined) {
          this.iwRepairValue = controlUIData[0].iw_repair[0].value;
          this.iwRepairShowInUI = controlUIData[0].iw_repair[0].show_in_ui;
          this.iwRepairEnabled = controlUIData[0].iw_repair[0].enabled;
        }
        if (controlUIData[0].ow_repair !== undefined) {
          this.owRepairValue = controlUIData[0].ow_repair[0].value;
          this.owRepairShowInUI = controlUIData[0].ow_repair[0].show_in_ui;
          this.owRepairEnabled = controlUIData[0].ow_repair[0].enabled;
        }
        if (controlUIData[0].final_test_post !== undefined) {
          this.finalTestPostValue = controlUIData[0].final_test_post[0].value;
          this.finalTestPostShowInUI = controlUIData[0].final_test_post[0].show_in_ui;
          this.finalTestPostEnabled = controlUIData[0].final_test_post[0].enabled;
        }
        if (controlUIData[0].engineering_eval !== undefined) {
          this.engineeringEvalValue = controlUIData[0].engineering_eval[0].value;
          this.engineeringEvalShowInUI = controlUIData[0].engineering_eval[0].show_in_ui;
          this.engineeringEvalEnabled = controlUIData[0].engineering_eval[0].enabled;
        }
        if (controlUIData[0].wipe_sanitize !== undefined) {
          this.wipeSanitizeValue = controlUIData[0].wipe_sanitize[0].value;
          this.wipeSanitizeShowInUI = controlUIData[0].wipe_sanitize[0].show_in_ui;
          this.wipeSanitizeEnabled = controlUIData[0].wipe_sanitize[0].enabled;
        }
        if (controlUIData[0].cosmetic_insp !== undefined) {
          this.cosmeticInspectionValue = controlUIData[0].cosmetic_insp[0].value;
          this.cosmeticInspectionShowInUI = controlUIData[0].cosmetic_insp[0].show_in_ui;
          this.cosmeticInspectionEnabled = controlUIData[0].cosmetic_insp[0].enabled;
        }
        if (controlUIData[0].audit !== undefined) {
          this.auditValue = controlUIData[0].audit[0].value;
          this.auditShowInUI = controlUIData[0].audit[0].show_in_ui;
          this.auditEnabled = controlUIData[0].audit[0].enabled;
        }
        if (controlUIData[0].audit_sampling_rate !== undefined) {
          this.auditSamplingRateValue = controlUIData[0].audit_sampling_rate[0].value;
          this.auditSamplingRateShowInUI = controlUIData[0].audit_sampling_rate[0].show_in_ui;
          this.auditSamplingRateEnabled = controlUIData[0].audit_sampling_rate[0].enabled;
        }
        if (controlUIData[0].waiting_for_parts !== undefined) {
          this.waitingForPartsValue = controlUIData[0].waiting_for_parts[0].value;
          this.waitingForPartsShowInUI = controlUIData[0].waiting_for_parts[0].show_in_ui;
          this.waitingForPartsEnabled = controlUIData[0].waiting_for_parts[0].enabled;
        }
        if (controlUIData[0].firmware !== undefined) {
          this.firmwareValue = controlUIData[0].firmware[0].value;
          this.firmwareShowInUI = controlUIData[0].firmware[0].show_in_ui;
          this.firmwareEnabled = controlUIData[0].firmware[0].enabled;
        }
      }
    },
    //Delete warehouse confirmation popup
    deleteDialog() {
      this.warehouseDelDialog = true;
      this.warehouseDialog = false;
    },
    //Delete Warehouse Dialog
    wareDelete() {
      this.warehouseList = this.warehouseList.filter((obj) => obj.ware_id !== this.selectedWarehouse);
      if (this.warehouseList.length == 1) {
        this.searchDisabled = true;
        this.search = "";
      }
      this.warehouseDelDialog = false;
    },
    //Warehouse - End

    //Substitution Part - Start
    //New Substitution Part List
    newSubstitutionPart() {
      this.part_key = "";
      this.numberPart = "";
      this.descriptionPart = "";
      this.partNumberSearch = "";
      this.partNumWarehouse = "";
      this.valPartNumFill = false;
      this.partNumSearched = true;
      this.showPartDetails = false;
      this.substitutionPartListDialog = true;
      this.saveSubstitutionDet = true;
      this.editSubstitutionDet = false;
    },
    //Substitution data table details
    async postSubstitutionPartsList() {
      this.partDetails = true;
      let substitutionObj = {
        UserId: this.userId,
        SearchStr: this.partNumberSearch,
        part_num: "",
        proj_key: this.selectedProject,
      };
      let substitutionPathData = await PartService.postSubstitutionPartsList("post", substitutionObj);
      if (substitutionPathData) {
        this.substitutionList = substitutionPathData;
      }
    },
    //Selected Part Details
    showPartDesc(item) {
      this.substitutionList = [];
      this.showPartDetails = true;
      this.partNumSearched = false;
      this.partNumWarehouse = "";
      this.numberPart = item.Part_Number;
      this.descriptionPart = item.Description;
      this.part_key = item.part_id;
      this.partNumberSearch = item.Part_Number;
      if (this.listSubstitutionDetails.length > 0) {
        this.subDuplicate = this.listSubstitutionDetails.find((elem) => elem.partNumber == this.numberPart);
      }
      if (this.subDuplicate == 0 || this.subDuplicate == undefined) {
        this.getWarehouseDetails(item.part_id);
      } else {
        this.subDuplicate = 0;
        this.showPartDetails = false;
        this.partNumSearched = true;
        this.numberPart = "";
        this.descriptionPart = "";
        this.part_key = "";
        this.partNumberSearch = "";
        let Alert = {
          type: "error",
          isShow: true,
          message: "Sub Part is already used.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Save button click event in Substitution Part List
    saveSubstitutionPartInfo() {
      this.substitutionSaveDisabled = true;
      this.substitutionPartListDialog = false;
      let warehouseData = this.warehousePartNumList.filter((obj) => obj.ware_id === this.partNumWarehouse);
      let substitutionObj = {
        sequence: this.listSubstitutionDetails.length + 1,
        part_key: this.part_key,
        partNumber: this.numberPart,
        description: this.descriptionPart,
        ware: warehouseData[0].ware,
        ware_key: warehouseData[0].ware_id,
      };
      this.listSubstitutionDetails.push(substitutionObj);
    },
    //Substitution Edit Function - Dont remove - CP
    async substitutionPartEdit(item) {
      this.subPartDelete = true;
      this.substitutionSaveDisabled = true;
      this.sequence = 0;
      this.sequence = item.sequence;
      this.substitutionPartListDialog = true;
      this.partNumberSearch = item.partNumber;
      this.numberPart = item.partNumber;
      this.descriptionPart = item.description;
      this.showPartDetails = true;
      this.partNumSearched = true;
      this.valPartNumFill = false;
      this.saveSubstitutionDet = false;
      this.editSubstitutionDet = true;
      let substitutionObj = {
        UserId: this.userId,
        SearchStr: this.partNumberSearch,
        part_num: "",
        proj_key: this.selectedProject,
      };
      let substitutionPathData = await PartService.postSubstitutionPartsList("post", substitutionObj);
      if (substitutionPathData) {
        this.substitutionList = substitutionPathData;
        this.partDetails = false;
      }
      let warehouseData = await PartService.getWarehouseDetails("get", item.part_key);
      if (warehouseData.length !== undefined) {
        this.warehousePartNumList = warehouseData;
      }
      this.partNumWarehouse = item.ware_key;
    },
    //Edit Substitution details
    editSubstitutionPartInfo() {
      this.substitutionSaveDisabled = true;
      this.substitutionPartListDialog = false;
      let warehouseData = this.warehousePartNumList.filter((obj) => obj.ware_id === this.partNumWarehouse);
      this.listSubstitutionDetails = this.listSubstitutionDetails.map((x) =>
        x.sequence == this.sequence
          ? {
            ...x,
            partNumber: this.numberPart,
            description: this.descriptionPart,
            part_key: this.part_key,
            ware: warehouseData[0].ware,
            ware_key: warehouseData[0].ware_id,
          }
          : x
      );
    },
    //Clear Substitutions Part Textbox
    clearSubstitutionParts() {
      this.substitutionList = [];
      this.showPartDetails = false;
      this.numberPart = "";
      this.descriptionPart = "";
      this.partNumWarehouse = "";
      this.partDetails = false;
    },
    //Substitution Part Delete Confirmation popup
    subDeleteDialog() {
      this.substitutionPartListDialog = false;
      this.subPartDelDialog = true;
    },
    //Substitution Part Delete
    deleteSubPart() {
      this.listSubstitutionDetails = this.listSubstitutionDetails.filter((obj) => obj.partNumber !== this.numberPart);
      this.subPartDelDialog = false;
    },
    //Substitution Part - End

    //Kit/Bundle - Start
    //New Kit/Bundle
    newMemberPart() {
      this.kitPartSearch = false;
      this.kitBundleDelete = false;
      this.editKitBundle = false;
      this.part_key = "";
      this.kitSearch = "";
      this.kitBundleDetails = false;
      this.kitBundleList = [];
      this.showKitBundleDetails = false;
      this.kitSearched = true;
      this.kitBundlePartDis = true;
      this.numberPart = "";
      this.descriptionPart = "";
      this.kitBundleQty = 1;
      this.kitBundleActive = false;
      this.kitBundlePrimaryPart = false;
      this.kitBundleAddOn = false;
      this.kitBOMDialog = true;
      this.saveKitBundle = true;
    },
    //Existing Kit Delete
    kitDeleteDialog() {
      this.kitBOMDialog = false;
      this.kitBundleDelDialog = true;
    },
    //Delete Kit
    deleteKitBundle() {
      this.listMemberPartDetails = this.listMemberPartDetails.filter((obj) => obj.member_part_key !== this.memberPartKey);
      this.kitBundleDelDialog = false;
    },
    //Kit Data Table Details
    async postKitBundleList() {
      this.kitBundleDetails = true;
      this.kitBundleList = [];
      this.showKitBundleDetails = false;
      this.numberPart = "";
      this.descriptionPart = "";
      this.kitBundleQty = 1;
      this.kitBundleActive = false;
      this.kitBundlePrimaryPart = false;
      this.kitBundleAddOn = false;
      let kitBundleObj = {
        UserId: this.userId,
        SearchStr: this.kitSearch,
        part_num: "",
        proj_key: this.selectedProject,
      };
      let kitBundleData = await PartService.postSubstitutionPartsList("post", kitBundleObj);
      if (kitBundleData) {
        this.kitBundleList = kitBundleData;
      }
    },
    //Selected Part Details
    showKitDesc(item) {
      this.kitBundleList = [];
      this.showKitBundleDetails = true;
      this.numberPart = item.Part_Number;
      this.descriptionPart = item.Description;
      this.part_key = item.part_id;
      this.kitSearch = item.Part_Number;
      if (this.listMemberPartDetails.length > 0) {
        this.kitDuplicate = this.listMemberPartDetails.find((elem) => elem.part_num == this.numberPart);
      }
      if (this.kitDuplicate == 0 || this.kitDuplicate == undefined) {
        this.kitSearched = false;
        this.kitBundlePartDis = false;
        if (this.listMemberPartDetails.length > 0) {
          let primaryPart = this.listMemberPartDetails.filter((obj) => obj.primary_pn == true);
          if (primaryPart.length > 0) {
            this.kitBundlePartDis = true;
          }
        }
        if (this.selectedProject == 89) {
          this.projectBrinks = true;
        } else {
          this.projectBrinks = false;
        }
      } else {
        this.kitDuplicate = 0;
        this.showKitBundleDetails = false;
        this.numberPart = "";
        this.descriptionPart = "";
        this.part_key = "";
        this.kitSearch = "";
        let Alert = {
          type: "error",
          isShow: true,
          message: "Part is already used.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Edit Kit/Bundle
    memberPartEdit(item) {
      this.kitSearched = false;
      this.kitBundlePartDis = false;
      this.kitPartSearch = true;
      this.kitBundleDelete = true;
      this.kitBOMDialog = true;
      this.kitSearch = item.part_num;
      this.showKitBundleDetails = true;
      this.numberPart = item.part_num;
      this.descriptionPart = item.part_desc;
      this.kitBundleQty = item.qty;
      this.kitBundleActive = item.active;
      this.kitBundlePrimaryPart = item.primary_pn;
      this.kitBundleAddOn = item.add_on;
      this.editKitBundle = true;
      this.saveKitBundle = false;
      this.memberPartKey = item.member_part_key;
    },
    //Clear Substitutions Part Textbox
    clearKitBundleList() {
      this.kitBundleList = [];
      this.showKitBundleDetails = false;
      this.numberPart = "";
      this.descriptionPart = "";
      this.kitBundleDetails = false;
    },
    //Save Kit Bundle Information
    saveKitBundleInfo() {
      this.kitBOMDialog = false;
      let kitBundleObj = {
        member_part_key: parseInt(this.part_key),
        part_num: this.numberPart,
        part_desc: this.descriptionPart,
        qty: parseInt(this.kitBundleQty),
        primary_pn: this.kitBundlePrimaryPart,
        active: this.kitBundleActive,
        add_on: this.kitBundleAddOn,
      };
      if (this.kitBundlePrimaryPart == true) {
        this.kitBundlePartDis = true;
      }
      this.listMemberPartDetails.push(kitBundleObj);
    },
    //Edit Kit Bundle Information
    editKitBundleInfo() {
      this.kitBOMDialog = false;
      this.listMemberPartDetails = this.listMemberPartDetails.map((x) =>
        x.member_part_key == this.memberPartKey
          ? {
            ...x,
            part_num: this.numberPart,
            part_desc: this.descriptionPart,
            qty: parseInt(this.kitBundleQty),
            primary_pn: this.kitBundlePrimaryPart,
            active: this.kitBundleActive,
            add_on: this.kitBundleAddOn,
          }
          : x
      );
    },
    //Kit/Bundle - End

    //API for validation of checkboxes
    async postControlUIDetails(name, value) {
      let wareKey = 0; //Do not delete - CP
      let controlUIObj = {
        ptype_key: parseInt(this.selectedPartType),
        checkbox_name: name,
        checkbox_value: value,
        bcn: this.bcnValue,
        serial_number: this.serialNoCapturedValue,
        ware_key: parseInt(wareKey),
        iw_repair: this.iwRepairValue,
        ow_repair: this.owRepairValue,
        gobi: this.gobiValue,
        esn_imei: this.esnIMEIValue,
        iccid: this.ICCIDCapturedValue,
      };
      let controlUIData = await PartService.postControlUIDetails("post", controlUIObj);
      if (controlUIData[0] !== undefined) {
        if (controlUIData[0].auto_generate_bcn !== undefined) {
          this.autoGenerateBCNValue = controlUIData[0].auto_generate_bcn[0].value;
          this.autoGenerateBCNShowInUI = controlUIData[0].auto_generate_bcn[0].show_in_ui;
          this.autoGenerateBCNEnabled = controlUIData[0].auto_generate_bcn[0].enabled;
        }
        if (controlUIData[0].auto_generated_prefix !== undefined) {
          this.autoGeneratedPrefixValue = controlUIData[0].auto_generated_prefix[0].value;
          this.autoGeneratedPrefixShowInUI = controlUIData[0].auto_generated_prefix[0].show_in_ui;
          this.autoGeneratedPrefixEnabled = controlUIData[0].auto_generated_prefix[0].enabled;
        }
        if (controlUIData[0].gobi !== undefined) {
          this.gobiValue = controlUIData[0].gobi[0].value;
          this.gobiShowInUI = controlUIData[0].gobi[0].show_in_ui;
          this.gobiEnabled = controlUIData[0].gobi[0].enabled;
        }
        if (controlUIData[0].esn_imei !== undefined) {
          this.esnIMEIValue = controlUIData[0].esn_imei[0].value;
          this.esnIMEIShowInUI = controlUIData[0].esn_imei[0].show_in_ui;
          this.esnIMEIEnabled = controlUIData[0].esn_imei[0].enabled;
        }
        if (controlUIData[0].iccid !== undefined) {
          this.ICCIDCapturedValue = controlUIData[0].iccid[0].value;
          this.ICCIDCapturedShowInUI = controlUIData[0].iccid[0].show_in_ui;
          this.ICCIDCapturedEnabled = controlUIData[0].iccid[0].enabled;
        }
        if (controlUIData[0].third_strike_visit !== undefined) {
          this.thirdStrikeValue = controlUIData[0].third_strike_visit[0].value;
          this.thirdStrikeShowInUI = controlUIData[0].third_strike_visit[0].show_in_ui;
          this.thirdStrikeEnabled = controlUIData[0].third_strike_visit[0].enabled;
        }
        if (controlUIData[0].minimum_revision !== undefined) {
          this.minRevLevelValue = controlUIData[0].minimum_revision[0].value;
          this.minRevLevelShowInUI = controlUIData[0].minimum_revision[0].show_in_ui;
          this.minRevLevelEnabled = controlUIData[0].minimum_revision[0].enabled;
        }
      }
    },
    //Build options change
    buildOptionsChange() {
      this.buildOptions = [];
      if (this.formData.build_options[0].value.length > 0) {
        for (let i = 0; i < this.formData.build_options[0].value.length; i++) {
          let optBuild = this.buildOptionsList.filter((obj) => obj.list_id === this.formData.build_options[0].value[i]);
          let obj = {
            sequence: i + 1,
            build_option: optBuild[0].displaystring,
            list_id: this.formData.build_options[0].value[i],
          };
          if (this.formData.build_options[0].value[i] !== undefined) {
            this.buildOptions.push(obj);
          }
        }
      }
    },
    modelChange() {
      this.partJSON[0].model = this.formData.model[0].value;
    },
    oemModelChange() {
      this.partJSON[0].oem_model = this.formData.oem_model[0].value;
    },
    //Final Submit API
    submitPartAddDetails() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.partJSON[0].part_id = 0;
      this.partJSON[0].proj_key = parseInt(this.selectedProject);
      this.partJSON[0].part_number = this.partNumber;
      this.partJSON[0].part_description = this.partDesc;
      this.partJSON[0].active = true;
      this.partJSON[0].ptype_key = parseInt(this.selectedPartType);
      this.partJSON[0].class_key = parseInt(this.formData.part_class[0].value);
      this.partJSON[0].oem_key = parseInt(this.formData.oem[0].value);
      this.partJSON[0].odm_key = parseInt(this.formData.odm[0].value);
      this.partJSON[0].oem_part = this.oemPartNum;
      this.partJSON[0].country_id = parseInt(this.selectedCountry);
      this.partJSON[0].size = parseInt(this.displaySizeVal);
      if (this.formData.model[0].value["model"] !== undefined) {
        this.partJSON[0].model = this.formData.model[0].value["model"];
      } else {
        this.partJSON[0].model = this.formData.model[0].value;
      }
      if (this.formData.oem_model[0].value["model"] !== undefined) {
        this.partJSON[0].oem_model = this.formData.oem_model[0].value["model"];
      } else {
        this.partJSON[0].oem_model = this.formData.oem_model[0].value;
      }
      this.partJSON[0].uom_key = parseInt(this.formData.unit_of_measure[0].value);
      this.partJSON[0].bin_type_key = parseInt(this.formData.bin_type[0].value);
      this.partJSON[0].bin_limit = parseInt(this.formData.bin_limit[0].value);
      this.partJSON[0].packout_ref = this.formData.packout_ref[0].value;
      this.partJSON[0].box_label_size_key = parseInt(this.formData.box_label_size[0].value);
      this.partJSON[0].height = parseInt(this.formData.height[0].value);
      this.partJSON[0].width = parseInt(this.formData.width[0].value);
      this.partJSON[0].length = parseInt(this.formData.length[0].value);
      this.partJSON[0].weight = parseInt(this.formData.weight[0].value);
      this.partJSON[0].unit_cost = parseFloat(this.formData.unit_cost[0].value);
      this.partJSON[0].sale_price = parseFloat(this.formData.sale_price[0].value);
      this.partJSON[0].exchange_price = parseFloat(this.formData.exchange_price[0].value);
      this.partJSON[0].repair_price = parseFloat(this.formData.repair_price[0].value);
      this.partJSON[0].so_warranty_days = parseInt(this.formData.so_warranty_days[0].value);
      this.partJSON[0].fgi_dsi_cutoff = parseInt(this.formData.fgi_dsi_cutoff[0].value);
      this.partJSON[0].oem_dsi_cutoff = parseInt(this.formData.oem_dsi_cutoff[0].value);
      this.partJSON[0].oem_parts_per_skid = parseInt(this.formData.oem_parts_per_skid[0].value);
      this.partJSON[0].cf_buffer_qty = parseInt(this.formData.cf_buffer_qty[0].value);
      this.partJSON[0].sales_orders = this.enableSalesOrderValue;
      this.partJSON[0].so_straight_sale = this.soStraightSaleValue;
      this.partJSON[0].so_advanced_exchange = this.soAdvancedExchangeValue;
      this.partJSON[0].so_exchange = this.soExchangeValue;
      this.partJSON[0].so_customer_repair = this.soCustRepairValue;
      this.partJSON[0].so_straight_return = this.soStraightReturnValue;
      this.partJSON[0].purchase_orders = this.enablePurchaseOrdersValue;
      this.partJSON[0].po_straight_purchase = this.poStraightPurchaseValue;
      this.partJSON[0].po_advanced_exchange = this.poAdvanceExchangeValue;
      this.partJSON[0].po_exchange = this.poExchangeValue;
      this.partJSON[0].po_stock_repair = this.poStockRepairValue;
      this.partJSON[0].po_return = this.poPOReturnValue;
      this.partJSON[0].bcn = this.bcnValue;
      this.partJSON[0].auto_generate_bcn = this.autoGenerateBCNValue;
      this.partJSON[0].auto_generated_prefix = this.autoGeneratedPrefixValue;
      this.partJSON[0].serial_number = this.serialNoCapturedValue;
      this.partJSON[0].gobi = this.gobiValue;
      this.partJSON[0].esn_imei = this.esnIMEIValue;
      this.partJSON[0].iccid = this.ICCIDCapturedValue;
      this.partJSON[0].box_label = this.boxLabelValue;
      this.partJSON[0].print_comment_sheet = this.printCommentSheetValue;
      this.partJSON[0].prompt_for_new = this.promptForNewProductValue;
      this.partJSON[0].third_strike = this.activateStrikeRuleValue;
      this.partJSON[0].third_strike_visit = this.thirdStrikeValue;
      this.partJSON[0].return_waybill_required = this.returnWaybillLabelRequiredValue;
      this.partJSON[0].revision_level = this.promptRevLevelValue;
      this.partJSON[0].minimum_revision = this.minRevLevelValue;
      this.partJSON[0].firmware_revision = this.promptFirmLevelValue;

      //Build Order Values
      this.partJSON[0].build = this.buildOptions;

      //Cosmetic Inspection Kitting Value
      this.partJSON[0].kit = this.cosmetic;

      //ECO Information Value
      this.partJSON[0].eco = this.ecoInfo;

      //Substitution Part JSON push
      this.partJSON[0].subs = this.listSubstitutionDetails.map((el) => {
        return {
          sequence: el.sequence,
          part_key: el.part_key,
          ware_key: el.ware_key,
        };
      });
      for (let i = 0; i < this.partJSON[0].subs.length; i++) {
        this.partJSON[0].subs[i].sequence = i + 1;
      }

      //Warehouse part JSON push
      this.partJSON[0].warehouse = this.warehouseList.map((el) => {
        return {
          sequence: el.sequence,
          pware_key: 0,
          part_key: 0,
          ware_key: parseInt(el.ware_id),
          avg_cost: parseInt(el.avg_cost),
          lead_time: parseInt(el.lead_time),
          dsi: parseInt(el.dsi),
          reorder_point: parseInt(el.reorder_point),
          cra: el.cra,
          dnc_rcv: el.dnc_rcv,
          ifir: el.ifir,
          ifir_days: parseInt(el.ifir_days),
          returns_to_scrap: el.returns_to_scrap,
          dnc_bypass_scrap: el.dnc_bypass_scrap,
          initial_test: el.initial_test,
          iw_test_path_key: parseInt(el.iw_test_path),
          ow_test_path_key: parseInt(el.ow_test_path),
          final_test_pre: el.final_test_pre,
          ow_repair: el.ow_repair,
          iw_repair: el.iw_repair,
          waiting_for_parts: el.waiting_for_parts,
          firmware: el.firmware,
          final_test_post: el.final_test_post,
          engineering_eval: el.engineering_eval,
          wipe_sanitize: el.wipe_sanitize,
          cosmetic_insp: el.cosmetic_insp,
          audit: el.audit,
          audit_sampling_rate: parseInt(el.audit_sampling_rate),
          harvest: el.harvest,
        };
      });
      for (let i = 0; i < this.partJSON[0].warehouse.length; i++) {
        this.partJSON[0].warehouse[i].sequence = i + 1;
      }

      //Kit/Bundle JSON
      this.partJSON[0].bom = this.listMemberPartDetails.map((el) => {
        return {
          member_part_key: el.member_part_key,
          qty: el.qty,
          primary_pn: el.primary_pn,
          active: el.active,
          add_on: el.add_on,
        };
      });

      this.user_key = parseInt(this.userId);
      let jsonObj = {
        json: JSON.stringify(this.partJSON[0]),
      };
      this.axios
        .post("/pe/parts_create", jsonObj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            if (this.autoGenerateBCNValue == true && this.autoGeneratedPrefixValue == "") {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: "Auto-Generated Prefix is required",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else if (this.promptRevLevelValue == true && this.minRevLevelValue == "") {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: "Minimum Revision Level Required",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else if (this.activateStrikeRuleValue == true && this.thirdStrikeValue == 0) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: "Strike Rule Is Checked But Visit Is Zero",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              let responseData = JSON.parse(response.data.body.message);
              this.isEditing = false;
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: "Part created successfully",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.$router.push(`/parts-update/${btoa(responseData.toString())}`);
            }
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          let res = JSON.parse(error.response.data.message);
          let errorString = "";
          res.Errors.forEach((obj) => {
            errorString += obj.Error + ",";
          });
          errorString = errorString.slice(0, -1);
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: errorString,
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
  },
  components: {
    breadcrumbComp,
    draggable,
    BackToTop,
  },
};
